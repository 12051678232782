import React from 'react'
import { Confirmation, Container } from '@components'
import { OrderType } from '@types'

import { DefaultLayout } from '../layouts/defaultlayout'
// import { testObject } from './mockObject'

interface Props {
  location: {
    state:
      | {
          order: OrderType
        }
      | undefined
  }
}

// eslint-disable-next-line import/no-default-export
export default ({ location }: Props) => {
  return (
    <DefaultLayout isDataLoaded>
      <Container fullHeight>
        <Confirmation state={location.state} />
      </Container>
    </DefaultLayout>
  )
}
